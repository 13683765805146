import React, { useRef } from 'react';
import './Header.css';

const Header = ({ onDataLoad,onError }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  function replaceCommasInQuotes(text) {
    return text.replace(/"([^"]*)"/g, function(match) {
      return match.replace(/,/g, ":;");
    });
  }

  const parseCSV = (text) => {
    let headers = null;
    let data = null;

    if (typeof text === "string") {
      const rows = text.trim().split('\n');
      if (rows.length < 1) {
        onError('CSV data is insufficient.')
        return;
      }
      headers = rows[0].split(',').map(header => header.trim().replace(/\s+/g, ''));
      let i = 0;
      data = rows.slice(1).map(row => {
        i++;
        const values = row.split(',').map(value => value.trim().replace(/\s+/g, ' '));
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index] ? values[index].replace(/:;/g, ',') : '';
          obj['id'] = i;
          return obj;
        }, {});
      });
    } else {
      headers = text[0].map(header => header.trim().replace(/\s+/g, ''));
      let i = 0;
      data = text.slice(1).map(row => {
        i++;
        const values = row.map(value => String(value).trim().replace(/\s+/g, ' '));
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index] ? values[index] : '';
          obj['id'] = i;
          return obj;
        }, {});
      });
    }

    const map = {};
    const result = [];

    if (!headers.includes('ModelID')) {
      onError('Data is invalid.')
      return;
    }else{
      onError('');
    }

    data.push({
      "ModelID": "CASI-D",
      "Algorithm": "",
      "Parent": "Root",
      "Type": "",
      "PrimaryUseCase": "",
      "KeyFeatures": "",
      "ExamplesofApplication": "",
      "TimestampCreation": "",
      "TimestampCompletion": "",
      "TimestampUpdate": "",
      "AgeofModel": "",
      "VersionControl": "",
      "WatermarkingID": "",
      "WatermarkingValidation": "",
      "id": 0
    });

    const allParents = [];
    data.forEach(item => {
      allParents.push(item.Parent);
    });

    data.forEach(item => {
      if (allParents.includes(item['ModelID'])) {
        map[item['ModelID']] = { ...item, children: [] };
      } else {
        map[item['ModelID']] = { ...item };
      }
    });

    data.forEach(item => {
      if (item.Parent) {
        if (map[item.Parent]) {
          map[item.Parent].children.push(map[item['ModelID']]);
        }
      }
    });

    for (let x of Object.keys(map)) {
      if (map[x].Parent === "Root") {
        result.push(map[x]);
      }
    }    

    if (result.length < 1) {
      onError('CSV data is invalid.')
      return null;
    } else {
      onError('');
      return result[0];
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if(!file) {
			onError("Please select a CSV or Excel file.");
			return;
		}else{
      onError('');
    }
    if (file) {
      const reader = new FileReader();
			reader.onload = (event) => {
        let text = event.target.result;
        text = replaceCommasInQuotes(text);
        const parsedResult = parseCSV(text);
				if(parsedResult) {
					onDataLoad(parsedResult)
				}
			};
      reader.readAsText(file);
    }
  };

  return (
    <header className="header">
      <button className="header-btn" onClick={handleButtonClick}>
        LOAD MODEL CARDS
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }} // Hide the file input
        onChange={handleFileChange}
      />
    </header>
  );
};

export default Header;
