import React, { useEffect, useRef, useState } from 'react';
import './FlowChart.css';
import Modal from '../Modal/Modal';
import LearningTypes from '../LearningType/LearningType';


const colors = ['#6D59A6', '#6D59A6', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'];

function FlowChart({ data, highlightNode,searchData,searchValue}) {
  const containerRef = useRef(null);
  const [hoveredNode, setHoveredNode] = useState(null);
  const showData = (data, e) => {
    setHoveredNode({ data: data.Algorithm !== '' ? data : null, event: e });
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      createFlowChart(containerRef.current, data);
    }
  }, [data]);

  const createFlowChart = (container, data, level = 0) => {
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
    if (data && Object.keys(data).length > 0) {
      const ul = document.createElement('ul');
      const li = document.createElement('li');
      const div = document.createElement('div');
      const arrowDiv = document.createElement('p');
      const a = document.createElement('a');
      a.classList.add("model-titel-a");
      const color = colors[level % colors.length];
      a.textContent = data.ModelID;

      if (data.Parent !== "Root") {
        div.classList.add("arrow-bottom-div");
        arrowDiv.classList.add("arrow-bottom");
        a.style.backgroundColor = "#FFFFFF";
        a.style.color = color;
        a.style.border = `2px solid ${color}`;
      } else {
        a.classList.add("a-1")
        a.style.backgroundColor = "#BFB6D8";
        a.style.color = "#000";
        a.style.border = `2px solid ${color}`;
      }

      if (data.Type === "Supervised Learning") {
        ul.classList.add('supervised');
        if (data.Parent !== "CASI-D") {
          arrowDiv.classList.add("arrow-bottom", "supervised-div");
        } else {
          arrowDiv.classList.add("arrow-bottom");
        }
      } else if (data.Type === "Ensemble Learning") {
        ul.classList.add('ensemble');
        if (data.Parent !== "CASI-D") {
          arrowDiv.classList.add("arrow-bottom", "ensemble-div");
        } else {
          arrowDiv.classList.add("arrow-bottom");
        }
      } else if (data.Type === "Unsupervised Learning") {
        ul.classList.add('unsupervised');
        if (data.Parent !== "CASI-D") {
          arrowDiv.classList.add("arrow-bottom", "unsupervised-div");
        } else {
          arrowDiv.classList.add("arrow-bottom");
        }
      } else if (data.Type === "Reinforcement Learning") {
        ul.classList.add('reinforcement');
        if (data.Parent !== "CASI-D") {
          arrowDiv.classList.add("arrow-bottom", "reinforcement-div");
        } else {
          arrowDiv.classList.add("arrow-bottom");
        }
      }

      a?.addEventListener('mouseover', (e) => {
        showData(data, e)
      });

      a?.addEventListener('mouseout', () => {
        setHoveredNode(null)
      });

      div.appendChild(arrowDiv);
      div.appendChild(a);
      li.appendChild(div);

      if (data.children && data.children.length > 0) {
        data.children.forEach(child => {
          const childContainer = document.createElement('li');
          createFlowChart(childContainer, child, level + 1);
          ul.appendChild(childContainer);
        });
        li.appendChild(ul);
      } else {
        li.classList.add('last-child');
      }
      container.appendChild(li);
      const zoomContainer = document.getElementById('tree');
      addZoomFunctionality(zoomContainer)
    }
  };

  useEffect(() => {
    const query = highlightNode?.trim().toLowerCase();
    const value = searchValue?.trim().toLowerCase();
    const fieldValue = searchData;
    const nodes = document.querySelectorAll("#tree ul li");
    if (query || value) {
        const modelIDs = extractModelIDs(data, query, fieldValue, value, []);
        nodes.forEach((node) => {
            const text = node.querySelector("a").textContent.toLowerCase();
            if (modelIDs.includes(text)) {
                let current = node;
                while (current && current.tagName === "LI") {
                  current = current.parentNode.parentNode; // Move up to parent
                  node.querySelector("a").style.zoom = "100%"; // Show node
                  node.querySelector("a").style.background = "#C0D3FF";
                  node.querySelector("a").style.color = "#000000";
                }
            } else {
                let current = node;
                while (current && current.tagName === "LI") {
                  current = current.parentNode.parentNode; // Move up to parent
                  node.querySelector("a").style.zoom = "100%"; // Show node
                  node.querySelector("a").style.background = "#FFFFFF";
                  node.querySelector("a").style.color = "#000000";
                }
            }
        });
    } else {
      createFlowChart(containerRef.current,data);
    }
  },[highlightNode,searchValue])

  function addZoomFunctionality(container) {
    if (container) {
      let scale = 1;
      let offsetX = 0;
      let offsetY = 0;

      const zoomHandler = (event) => {
        event.preventDefault();
        const delta = event.deltaY > 0 ? 0.9 : 1.1;
        scale *= delta;
        container.style.transform = `scale(${scale}) translate(${offsetX}px, ${offsetY}px)`;
      };

      const panHandler = (event) => {
        if (event.buttons === 1) {
          offsetX += event.movementX;
          offsetY += event.movementY;
          container.style.transform = `scale(${scale}) translate(${offsetX}px, ${offsetY}px)`;
        }
      };

      container.addEventListener('wheel', zoomHandler);
      container.addEventListener('mouseup', () => container.removeEventListener('mousemove', panHandler));
      container.addEventListener('mousedown', (event) => {
        if (event.button === 0) {
          container.addEventListener('mousemove', panHandler);
        }
      });
    }
  }
  function extractModelIDs(data, searchQuery, anotherQuery, searchValue, modelIDs = []) {
    if (searchQuery || searchValue) {
        if (data.ModelID) {
            let searchConditions = [];

            if (searchQuery) {
                searchConditions.push(data.Algorithm.toLowerCase().includes(searchQuery));
            }

            if (searchValue) {
                searchConditions.push(
                  data[anotherQuery]?.toLowerCase().includes(searchValue)
                );
            }

            if (searchQuery && searchValue) {
                searchConditions = [
                  data.Algorithm.toLowerCase().includes(searchQuery),
                  data[anotherQuery]?.toLowerCase().includes(searchValue)
                ];
          }

            if (searchConditions.every(condition => condition)) {
              modelIDs.push(data.ModelID.toLowerCase());
            }
        }

        if (data.children && Array.isArray(data.children)) {
            data.children.forEach(child => extractModelIDs(child, searchQuery, anotherQuery,searchValue, modelIDs));
        }

        return modelIDs;
    } else {
      return [];
    }
  }

  return <div className='flow-chart-div'>
    {data && Object.keys(data).length > 0 && <p>On Hover node details will be shown</p>}
    <div className='tree' id='tree'>
      <div className='flowchart-container' ref={containerRef} />
    </div>
    {hoveredNode && hoveredNode.data !== null && (
      <Modal hoveredNode={hoveredNode}></Modal>
    )}
    {data && Object.keys(data).length > 0 && (<LearningTypes />)}
  </div>;
}

export default FlowChart;