import React, { useState } from 'react';
import './ChartSelector.css';
import exampleImage from '../../assets/images/icons8-info-26.png';
import ImageWithTooltip from '../ImageWithTooltip/ImageWithTooltip';

const ChartSelector = ({ options,csvData, onDataChange,label,isAddIcon,isAddClass}) => {
  const [selectedView, setSelectedView] = useState('Tree');

  const handleChange = (event) => {
    setSelectedView(event.target.value);
    onDataChange(event.target.value);
  };

  return (
    <div className="chart-selector">
      <div className='chart-select-div'>
        <label htmlFor="view-select" className={`label ${isAddClass ? 'add-label-class' : ''}`}>
          {label}
          {label && <ImageWithTooltip toolTipText={"Select a view to display different node"}></ImageWithTooltip>}
        </label>
        <select
          id="view-select"
          value={selectedView}
          onChange={handleChange}
          className="custom-select"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value} className='option-value'>
              {option.label}
            </option>
          ))}
        </select>
        {isAddIcon && <span className="dropdown-icon"></span>}
      </div>
      <div>
      </div>
    </div>
  );
};

export default ChartSelector;
