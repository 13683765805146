import React from 'react';
import './LearningType.css';


const LearningTypes = () => {
  const data = [
    { label: 'Supervised Learning', color: '#AF776F' },
    { label: 'Ensemble Learning', color: '#593D9F' },
    { label: 'Unsupervised Learning', color: '#6BE46D' },
    { label: 'Reinforcement Learning', color: '#43AFA1' },
  ];
  return (
    <div className='arrow-details-div'>
        {data.map((item, index) => (
            <div
            key={index}
            className='arrow-div'
            >
            <svg
                width="60"
                height="12"
                viewBox="0 0 60 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className='arrow-svg'
            >
                <path
                  d="M60 6L50 0.226497V11.7735L60 6ZM0 7L51 7V5L0 5L0 7Z"
                  fill={item.color}
                  class="exclude-path"
                />
            </svg>
            <span className='arrow-div-span'>{item.label}</span>
            </div>
        ))}
    </div>
  );
};

export default LearningTypes;
