import { useState } from 'react';
import './App.css';
import ChartSelector from './components/ChartSelector/ChartSelector';
import Header from './components/Header/Header';
import InputComponent from './components/InputComponent/InputComponent'
import FlowChart from './components/FlowChart/FlowChart'
import Tree from './components/Tree/Tree'
import DescendentFanChart from './components/DescendentFanChart/DescendentFanChart'
import ImageWithTooltip from './components/ImageWithTooltip/ImageWithTooltip';
import exampleData from './example.json'

const options = [
  { value: 'Flow chart', label: 'Flow chart' },
  { value: 'Tree', label: 'Tree' },
  { value: 'Descendent fan chart', label: 'Descendent fan chart' },
];

const searchOptions = [
  {value: "Type", label: "Type"},
  {value: "PrimaryUseCase", label: "Primary Use Case "},
  {value: "ExamplesofApplication", label: "Application"},
]

function App() {
  const [csvData, setCSVdata] = useState(exampleData);
  const [errorData, setError] = useState('');
  const [selectedView, setView] = useState('Tree');
  const [highlightNode, setHighlightNode] = useState(null); 
  const [highlightNodeFanChart, setHighlightNodeFanChart] = useState(null);
  const [searchData, setSearchData] = useState('Type');
  const [inputChnageSearchBox, setInputChangeSearchBox] = useState(null);
  const handleDataLoad = (data) => {
    setCSVdata(data);
  };
  const handleError = (data) => {
    setError(data);
  }

  const onDataChange = (data) => {
    if(csvData && !Object.keys(csvData).length){
      setError('Please upload a CSV or Excel file')
    }else{
      setError('');
    }
    setView(data);
  }

  const handleInputChange = (data) => {
    setHighlightNode(data);
    setHighlightNodeFanChart(data);
  }

  const handleInputChangeSeachBox = (data) => {
    setInputChangeSearchBox(data);
  }

  const onSearchDataChange = (data) => {
    setSearchData(data);
  }

  const renderView = () => {
    switch (selectedView) {
      case 'Flow chart':
        return <FlowChart data={csvData} highlightNode={highlightNode} searchData={searchData} searchValue={inputChnageSearchBox}/>;
      case 'Tree':
        return <Tree excelCsvData={csvData} highlightNode={highlightNode} searchData={searchData} searchValue={inputChnageSearchBox} />;
      case 'Descendent fan chart':
        return <DescendentFanChart data={csvData} highlightNode={highlightNodeFanChart} searchData={searchData} searchValue={inputChnageSearchBox} />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <Header onDataLoad={handleDataLoad} onError={handleError}></Header>
      <span className='error-div'>{errorData}</span>
      <div className='inputs-div'>
        <ChartSelector options={options} csvData={csvData} onDataChange={onDataChange} label="Select View:" isAddClass={true}></ChartSelector>
        <div className='search-box'>
          <div className='search-input-div'>
            <div className='first-search-box'>
              <InputComponent onChange={handleInputChange} label="Search By Algorithm:" isAdd={true}></InputComponent>
            </div>
            <div>
              <div className='searchBy'>Search By: <ImageWithTooltip toolTipText={"Use this to select a field and search related to it"}></ImageWithTooltip></div>
              <div className='merged-input'>
                <ChartSelector options={searchOptions} csvData={csvData} onDataChange={onSearchDataChange} label="" isAddIcon={true}></ChartSelector>
                <InputComponent onChange={handleInputChangeSeachBox} label=""></InputComponent>
              </div>
            </div>
          </div>
          <p className='para-info'>Highlighting nodes based on the specified search criteria.</p>
        </div>
      </div>
      {renderView()}
    </div>
  );
}

export default App;