import React, { useState } from 'react';
import eyeImage from '../../assets/images/icons8-info-26.png';
import './ImageWithTooltip.css';


const ImageWithTooltip = ({toolTipText}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={eyeImage}
        alt="Icon"
        className='eye-icon'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {isHovered && (
        <div className="tooltip">
          {toolTipText}
        </div>
      )}
    </div>
  );
};

export default ImageWithTooltip;