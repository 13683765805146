import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Modal from '../Modal/Modal';
import LearningTypes from '../LearningType/LearningType';
import './DescendentFanChart.css'; // Assuming you saved the CSS you provided in this file

const DescendentFanChart = ({ data,highlightNode,searchData,searchValue}) => {
  const chartRef = useRef(null);
  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const root = d3.hierarchy(data).sum(d => (d.children ? 0 : 1)).sort((a, b) => b.value - a.value);

      let allHeight = root.descendants().map(element => element.height);
      let greaterValues = allHeight.filter(height => height >= 10).length;

      if (greaterValues > 1) {
        d3.select(chartRef.current).style("transform", "scale(0.5) translate(0px, 0px)");
      } else {
        d3.select(chartRef.current).style("transform", "scale(1) translate(0px, 0px)");
      }

      const width = Math.max(1024, root.descendants().length * 50);
      const height = Math.max(900, root.descendants().length * 50);
      const radius = greaterValues > 10 ? (Math.min(width, height) / 2) - 200 : (Math.min(width, height) / 2) - 100;
      const depthMargin = 5;

      const svg = d3.select(chartRef.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

      const partition = d3.partition().size([2 * Math.PI, radius]);
      partition(root);

      const arc = d3.arc()
        .startAngle(d => d.x0)
        .endAngle(d => d.x1)
        .innerRadius(d => d.y0 + d.depth * depthMargin)
        .outerRadius(d => d.y1 + d.depth * depthMargin);

      const getColorScale = (dataDepth, dataType) => {
        const colorsData = {
          "Supervised Learning": ["#FFF8F7", "#FFDAD5", "#FCB9B0", "#E2A299", "#C98C84", "#AF776F", "#95635C", "#7C5049", "#633D38", "#492C28"],
          "Ensemble Learning": ["#F6F3FF", "#E3D8FF", "#D0BEFF", "#BDA3FF", "#AA88FF", "#8F6EE3", "#7354C1", "#593D9F", "#412A7D", "#2C1A5B"],
          "Unsupervised Learning": ["#F2FFF2", "#CCFFCD", "#A6FFA7", "#80FF82", "#6BE46D", "#57C759", "#45AA46", "#348D36", "#266F27", "#19521A"],
          "Reinforcement Learning": ["#F2FFFD", "#D4FFF9", "#B6FFF5", "#98FEF1", "#75F3E3", "#5AD1C2", "#43AFA1", "#2F8D81", "#1F6B61", "#114942"]
        };
        return dataType ? (colorsData[dataType][dataDepth - 1] || "#BFB6D8") : "#BFB6D8";
      };

      const getFontColor = (dataDepth) => {
        return dataDepth === 0 ? "#1C2C50" : dataDepth <= 5 ? "#000000" : "#FFFFFF";
      };

      svg.selectAll('path')
        .data(root.descendants())
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', d => getColorScale(d.depth, d.data.Type))
        .attr('stroke', d => d.depth === 0 ? "#6D59A6" : "#000000")
        .attr('stroke-width', "2px")
        .on('mouseover', (event, d) => {             
            if(!selectedNode){
                setSelectedNode({ data: d.data.Algorithm !== '' ? d.data : null,event:{ pageX: event.pageX, pageY: event.pageY }});
            }
          })
          .on('mouseout', () => {
            setSelectedNode(null); 
          });

      svg.selectAll('text')
        .data(root.descendants())
        .enter()
        .append('text')
        .attr('transform', d => d.depth === 0 ? `translate(0, 10)` : `translate(${arc.centroid(d)})`)
        .attr('text-anchor', 'middle')
        .attr('fill', d => getFontColor(d.depth))
        .text(d => d.data.ModelID)
        .style('font-size', d => d.depth === 0 ? "18px" : "14px");

      // Highlight nodes based on searchTerm
      if (highlightNode || searchValue) {
        highlightNodes(root, highlightNode,searchData,searchValue);
      }
    }

    return () => {
      d3.select(chartRef.current).selectAll('*').remove();
      const zoomContainer = document.getElementById('descendant-fan-chart-container');
      addZoomFunctionality(zoomContainer)
    };
  }, [data, highlightNode,searchValue]);

  // Function to add zoom and pan functionality
  function addZoomFunctionality(container) {
    if (container) {
      let scale = 1;
      let offsetX = 0;
      let offsetY = 0;

      const zoomHandler = (event) => {
        event.preventDefault();
        const delta = event.deltaY > 0 ? 0.9 : 1.1;
        scale *= delta;
        container.style.transform = `scale(${scale}) translate(${offsetX}px, ${offsetY}px)`;
      };

      const panHandler = (event) => {
        if (event.buttons === 1) {
          offsetX += event.movementX;
          offsetY += event.movementY;
          container.style.transform = `scale(${scale}) translate(${offsetX}px, ${offsetY}px)`;
        }
      };

      container?.addEventListener('wheel', zoomHandler);
      container?.addEventListener('mouseup', () => container?.removeEventListener('mousemove', panHandler));
      container?.addEventListener('mousedown', (event) => {
        if (event.button === 0) {
          container?.addEventListener('mousemove', panHandler);
        }
      });
    }
  }


  const highlightNodes = (root, searchQuery, anotherQuery, searchValue) => {
    const getFontColor = (dataDepth) => {
        return dataDepth === 0 ? "#1C2C50" : dataDepth <= 5 ? "#000000" : "#FFFFFF";
    };
    const getColorScale = (dataDepth, dataType) => {
        const colorsData = {
          "Supervised Learning": ["#FFF8F7", "#FFDAD5", "#FCB9B0", "#E2A299", "#C98C84", "#AF776F", "#95635C", "#7C5049", "#633D38", "#492C28"],
          "Ensemble Learning": ["#F6F3FF", "#E3D8FF", "#D0BEFF", "#BDA3FF", "#AA88FF", "#8F6EE3", "#7354C1", "#593D9F", "#412A7D", "#2C1A5B"],
          "Unsupervised Learning": ["#F2FFF2", "#CCFFCD", "#A6FFA7", "#80FF82", "#6BE46D", "#57C759", "#45AA46", "#348D36", "#266F27", "#19521A"],
          "Reinforcement Learning": ["#F2FFFD", "#D4FFF9", "#B6FFF5", "#98FEF1", "#75F3E3", "#5AD1C2", "#43AFA1", "#2F8D81", "#1F6B61", "#114942"]
        };
        return dataType ? (colorsData[dataType][dataDepth - 1] || "#BFB6D8") : "#BFB6D8";
    };

    d3.selectAll("path:not(.exclude-path)")
        .transition().duration(500)
        .attr("fill", d => {
            let matchesSearch = false;
            if (searchQuery) {
              matchesSearch = d?.data.Algorithm.toLowerCase().includes(searchQuery.toLowerCase())
            }  
            if (searchValue) {
              matchesSearch = d?.data[anotherQuery]?.toLowerCase().includes(searchValue.toLowerCase());
            }
            if (searchQuery && searchValue) {
                matchesSearch = false;
                matchesSearch = d?.data[anotherQuery]?.toLowerCase().includes(searchValue.toLowerCase()) && d?.data.Algorithm.toLowerCase().includes(searchQuery.toLowerCase());
            }

            return matchesSearch ? "#C0D3FF" : getColorScale(d?.depth, d?.data.Type);
        });

      d3.selectAll("text")
        .transition().duration(500)
        .attr("fill", d => {
            let matchesSearch = false;

            if (searchQuery) {
              matchesSearch = d.data.Algorithm.toLowerCase().includes(searchQuery.toLowerCase())
            } 
            if (searchValue) {              
              matchesSearch = d.data[anotherQuery]?.toLowerCase().includes(searchValue.toLowerCase());
            }
            if(searchQuery && searchValue){
              matchesSearch = false;
              matchesSearch = d.data[anotherQuery]?.toLowerCase().includes(searchValue.toLowerCase()) && d.data.Algorithm.toLowerCase().includes(searchQuery.toLowerCase());
            } 
            return matchesSearch ? "#000000" : getFontColor(d.depth);
        });
};

  return (
    <div className="fan-chart-div">
      {data && Object.keys(data).length > 0 && <p>On Hover node details will be shown</p>}
        <div id="descendant-fan-chart-container" ref={chartRef}></div>
        {selectedNode && selectedNode.data !== null && (
        <Modal hoveredNode={selectedNode}></Modal>
      )}
      {data && Object.keys(data).length > 0 && (<LearningTypes />)}
    </div>
  );
};

export default DescendentFanChart;
