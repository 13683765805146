import React, { useEffect } from 'react';
import './Modal.css';


const Modal = ({hoveredNode}) => {
    const safeRender = (value) => {
      return typeof value === 'object' ? JSON.stringify(value) : value || 'N/A';
    };

    function calcPosition(top, left) {
      let clientWidth = document.documentElement.clientWidth;
      if(clientWidth <= 765) return;
  
      const childNode = document.getElementById('modal');
      let heightCheck = document.getElementsByClassName(`heightcalc`)[0];
      const {height, width} = childNode.getBoundingClientRect();
  
      if(document.documentElement.clientWidth < (left + width + 25)) {
          const offset = document.documentElement.clientWidth - (left + width + 15);
          if(offset) {
            childNode.style.left = left - (width + 15) + "px";
          } else {
            childNode.style.left = left + offset + "px";
          }
      } else {
          childNode.style.left = left + "px";
      }
  
      if(document.documentElement.clientHeight < (top + height + 25)) {
          const offset = document.documentElement.clientHeight - (top + height + 15);
          childNode.style.top = top + offset +"px";
      } else {
          if(heightCheck){
              let widthCheck = document.getElementsByClassName(`drop-down-menu`)[0];
              widthCheck.style.width = heightCheck.clientWidth + "px"
              childNode.style.top = top + heightCheck.clientHeight +"px";
          }else{
              childNode.style.top = top + 25 +"px";
          }
      }
  }

  useEffect(() => {
    calcPosition(hoveredNode.event.pageY + 10, hoveredNode.event.pageX + 10)
  }, [hoveredNode])

    return(
        <div
          id='modal'
          style={{
            position: 'absolute',
            color: 'white',
            borderRadius: '8px',
            zIndex: 10,
            maxWidth: '650px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
            border: '1px solid #ccc',
            fontFamily: 'Roboto, sans-serif',
          }}
      >
        <h3 className='details-header'>{safeRender(hoveredNode.data.ModelID)}</h3>
        <div className='modal-div'>
          <label>Algorithm</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.Algorithm)}</span>
          <label>Type</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.Type)}</span>
          <label>Primary Use Case</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.PrimaryUseCase)}</span>
          <label>Key Features</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.KeyFeatures)}</span>
          <label>Examples of Application</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.ExamplesofApplication)}</span>
          <label>Timestamp Creation</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.TimestampCreation)}</span>
          <label>Timestamp Completion</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.TimestampCompletion)}</span>
          <label>Timestamp Update</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.TimestampUpdate)}</span>
          <label>Age of Model</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.AgeofModel)}</span>
          <label>Version Control</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.VersionControl)}</span>
          <label>Watermarking ID</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.WatermarkingID)}</span>
          <label>Watermarking Validation</label><span style={{ margin: '5px 0' }}> {safeRender(hoveredNode.data.WatermarkingValidation)}</span>
        </div>
      </div>
    )
}
export default Modal;