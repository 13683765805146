import React, { useEffect, useRef, useState } from 'react';
import { Network } from 'vis-network';
import { DataSet } from 'vis-data';

import Modal from '../Modal/Modal';
import LearningTypes from '../LearningType/LearningType';

const TreeChart = ({ excelCsvData,highlightNode,searchData,searchValue}) => {
    const containerRef = useRef(null);
    let networkRef = useRef(null);
    const [nodes,setNodes] = useState(new DataSet([]));
    const [hoveredNode, setHoveredNode] = useState(null);
    const [selectedNodes,setSelectedNode] = useState([])

  useEffect(() => {
    if (excelCsvData && Object.keys(excelCsvData).length > 0) {
      createTreeChart(excelCsvData);
    }
  },[excelCsvData]);
  function extractModelIDs(data, searchQuery, anotherQuery, searchValue, modelIDs = []) {
    if (searchQuery || searchValue) {
        if (data.ModelID) {
            let searchConditions = [];

            if (searchQuery) {
                searchConditions.push(data.Algorithm.toLowerCase().includes(searchQuery));
            }

            if (searchValue) {
                searchConditions.push(
                  data[anotherQuery]?.toLowerCase().includes(searchValue)
                );
            }

            if (searchQuery && searchValue) {
                searchConditions = [
                  data.Algorithm.toLowerCase().includes(searchQuery),
                  data[anotherQuery]?.toLowerCase().includes(searchValue)
                ];
          }

            if (searchConditions.every(condition => condition)) {
              modelIDs.push(data.ModelID.toLowerCase());
            }
        }

        if (data.children && Array.isArray(data.children)) {
            data.children.forEach(child => extractModelIDs(child, searchQuery, anotherQuery,searchValue, modelIDs));
        }

        return modelIDs;
    } else {
        return [];
    }
  }

function searchNode(searchText,anotherSearchText,searchValue) {
  let searchNodes = extractModelIDs(excelCsvData, searchText?.trim().toLowerCase(),anotherSearchText,searchValue?.trim().toLowerCase());  

  for (let node in selectedNodes) {
      nodes.update(selectedNodes[node]);
  }

  for (let modelID in searchNodes) {
      let foundNode = nodes.get({
          filter: function(item) {
              return item.label.toLowerCase().trim() === searchNodes[modelID].toLowerCase();
          }
      });

      if (foundNode.length > 0) {
          let nodeId = foundNode[0].id;
          setSelectedNode((prevNodes) => [...prevNodes, foundNode]);

          let color = {
              background: '#C0D3FF',
              border: '#333333',
              highlight: {
                  background: '#C0D3FF',
                  border: '#333333'
              }
          };

          nodes.update({
              id: nodeId,
              color: color
          });
      }
  }
}

  useEffect(() => {
    searchNode(highlightNode,searchData,searchValue);
  },[highlightNode,searchValue]);

  const restructureTree = (nodeId, level = 0) => {      
    const children = networkRef.getConnectedNodes(nodeId, 'to');
    const numChildren = children.length;
    

    if (numChildren > 0) {
      const parentPosition = networkRef.getPositions([nodeId])[nodeId];
      const totalLeafNodes = countLeafNodes(nodeId);
      const requiredWidth = (totalLeafNodes - 1) * 250;
      const startX = parentPosition.x - requiredWidth / 2;
      let currentX = startX;

      for (let i = 0; i < numChildren; i++) {
        const childId = children[i];
        const leafCount = countLeafNodes(childId);
        const childX = currentX + (leafCount - 1) * 75;
        const childY = parentPosition.y + 220 - level * 10;
        const childSize = Math.max(40 - level * 5, 13);
        const nodeData = nodes.get(childId); // Access nodes with nodes.current.get()
        
        // Check if node data is available
        if (nodeData) {
          const currentColor = nodeData.color || {
            border: '#6D59A6',
            background: '#fff',
            highlight: {
              background: '#C0D3FF',
              border: '#333333',
            },
          };

          // Update node properties
          nodes.update({
            id: childId,
            x: childX,
            y: childY,
            size: childSize,
            color: currentColor,
          });

          currentX += leafCount * 250;
          restructureTree(childId, level + 1);
        }
      }
      networkRef.fit();
    } else {
      const nodeData = nodes.get(nodeId); // Access nodes with nodes.current.get()
      
      
      // Check if node data is available
      if (nodeData) {
        nodes.update({
          ...nodeData,
          font: { color: '#fff' },
          color: {
            border: '#000',
            background: '#000',
            highlight: {
              background: '#C0D3FF',
              border: '#333333',
            },
          },
        });
      }
      networkRef.fit();
    }
  };

  const countLeafNodes = (nodeId) => {
    const children = networkRef.getConnectedNodes(nodeId, 'to');
    if (children.length === 0) {
      return 1;
    }
    return children.reduce((count, childId) => count + countLeafNodes(childId), 0);
  };

  const createTreeChart = (data) => {
    // Initialize nodes and edges
    // const nodes = new DataSet([]);
    const edges = [];

    // Function to recursively create nodes and edges
    const createTreeElements = (NodeData, parentId = null, x = 0, y = 0, level = 0) => {
      const childSize = Math.max(40 - level * 5, 10);
      let edgeColor = "#6D59A6"; // Default arrow color
      const ArrowColor = {
        'sup': '#AF776F',
        'unsup': '#6BE46D',
        'ensemble': '#593D9F',
        'reinfo': '#43AFA1',
      };

      // Set arrow color based on the Type
      if (NodeData.Type === "Ensemble Learning") {
        edgeColor = ArrowColor.ensemble;
      } else if (NodeData.Type === "Reinforcement Learning") {
        edgeColor = ArrowColor.reinfo;
      } else if (NodeData.Type === "Unsupervised Learning") {
        edgeColor = ArrowColor.unsup;
      } else if (NodeData.Type === "Supervised Learning") {
        edgeColor = ArrowColor.sup;
      }

      // Set node color based on conditions
      var color = (NodeData.ModelID === 0) ? {
        border: '#6D59A6',
        background: '#BFB6D8',
        highlight: {
          background: '#C0D3FF',
          border: '#333333'
        }
      } : {
        border: '#000',
        background: '#fff',
        highlight: {
          background: '#C0D3FF',
          border: '#333333'
        }
      };
      if(NodeData.Parent === "CASI-D") {
        color = {
          border: '#8574B5',
          background: '#fff',
          highlight: {
            background: '#C0D3FF',
            border: '#333333'
          }
        }
      }
      
        const existingNode = nodes.get(NodeData.ModelID);

        if (existingNode) {
          // If the node exists, update its properties
          nodes.update({
            id: NodeData.ModelID,
            label: `    ${NodeData.ModelID}    `,
            x: x,
            y: y,
            size: childSize,
            color: color,
            font: {
              size: childSize,
              color: NodeData.Parent === "CASI-D" ? "#8574B5" : "#000000",
              face: 'Roboto',
            },
          });
        } else {
          // If the node does not exist, add it
          nodes.add({
            id: NodeData.ModelID,
            label: `    ${NodeData.ModelID}    `,
            shape: 'circle',
            x: x,
            y: y,
            size: childSize,
            color: color,
            font: {
              size: childSize,
              color: NodeData.Parent === "CASI-D" ? "#8574B5" : "#000000",
              face: 'Roboto',
            },
          });
        }

      // Add edge between the parent node and the current node with the specified arrow color
      if (parentId !== null) {
        edges.push({
          from: parentId,
          to: NodeData.ModelID,
          arrows: { to: { enabled: true, scaleFactor: 1 } },
          color: { color: edgeColor, highlight: edgeColor, hover: edgeColor },
        });
      }

      // Recursively process child nodes
      if (NodeData.children) {
        NodeData.children.forEach((child, index) => {
          createTreeElements(child, NodeData.ModelID, x + (index * 150) - ((NodeData.children.length - 1) * 75), y + 100, level + 1);
        });
      }      
    };

    // Create nodes and edges
    createTreeElements(data);

    // Initialize the network
    const container = containerRef.current;
    const dataSet = {
      nodes: nodes,
      edges: edges,
    };
    

    const options = {
      interaction: { hover: true },
      layout: {
        hierarchical: {
          direction: "UD",
          sortMethod: "directed",
        },
      },
      physics: { enabled: false },
      nodes: {
        shape: 'circle',
        size: 30,
        color: { border: '#6D59A6', background: '#BFB6D8' },
        borderWidth: 2,
        font: { size: 14, color: '#000000', face: 'Roboto' },
        fixed: { x: true, y: true } 
      },
      edges: {
        smooth: false,
        width: 1,
        arrows: { to: { enabled: true, scaleFactor: 1 } },
      },
    };

    // Create the network
    networkRef = new Network(container, dataSet, options);

    networkRef.on('hoverNode', function(params) {
      const nodeId = params.node;

      if(nodeId) {
        if(!hoveredNode){
          getNodeData(excelCsvData, nodeId)
          let datas = getNodeData(excelCsvData, nodeId);
          setHoveredNode({ data: datas, event: params.event });
        }
      }
    });

    networkRef.on('blurNode', function() {
      setHoveredNode(null);
    });

    // Center the root node if exists
    const rootNode = nodes.get(0);
    if (rootNode) {
      const positions = {
        [rootNode.id]: { x: container.offsetWidth / 2, y: container.offsetHeight / 2 },
      };
      networkRef.moveTo({ position: positions[rootNode.id] });
    }

    networkRef.on("dragEnd", (params) => {
      if (params.nodes.length > 0) {
        const nodeId = params.nodes[0];
        restructureTree(nodeId);
      }
    });

    function getNodeData(data, nodeId) {
     if (data.ModelID === nodeId && data.Algorithm !== '') {
        return data;
      }
      if(data.children && data.children.length > 0) {
        for(const child of data.children) {
          const result = getNodeData(child, nodeId);
          if(result) {
            return result;
          }
        }
      }
      return null;
    }

    restructureTree('CASI-D');
  };

  return (
    <div>
      {excelCsvData && Object.keys(excelCsvData).length > 0 && <p>On Hover node details will be shown</p>}
      <div ref={containerRef} style={{ height: '700px', marginTop: '20px'}} className='tree'></div>
      {hoveredNode && hoveredNode.data !== null && (
        <Modal hoveredNode={hoveredNode}></Modal>
      )}
      {excelCsvData && Object.keys(excelCsvData).length > 0 && (<LearningTypes />)}
    </div>
  );
};

export default TreeChart;
