import React, { useState } from 'react';
import './InputComponent.css';
import ImageWithTooltip from '../ImageWithTooltip/ImageWithTooltip';

const SearchModel = ({onChange,label,isAdd}) => {
  const [search, setSearch] = useState('');

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    onChange(event.target.value)
  };

  return (
    <div className="search-model">
      <label htmlFor="search-input" className={`label ${isAdd ? 'add-class' : ''}`}>
      {label}
      {label && <ImageWithTooltip toolTipText={"Use this to search by the Algorithm field"}></ImageWithTooltip>}
      </label>
      <input
        id="search-label"
        type="text"
        className="custom-input"
        value={search}
        onChange={handleInputChange}
        placeholder='Search'
      />
    </div>
  );
};

export default SearchModel;